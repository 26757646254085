import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Col, Container, Row } from "react-bootstrap";

const ImpressumPage = () => {
  return (
    <Layout>
      <Seo
        title="Datenschutzerklärung"
        // description="Komplementäre Medizin ist ganzheitlich. Sie behandelt den ganzen Menschen – Körper, Geist und Seele – und nicht nur die Symptome."
      />

      <Container className="py-5">
        <Row className="justify-content-center">
          <Col>
            <h1 className="h2 section-title">Datenschutzerklärung</h1>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <h2 className="h3 section-title">1. Datenschutz</h2>
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </p>

            <p>
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
              mit denen Sie persönlich identifiziert werden können. Die
              vorliegende Datenschutzerklärung erläutert, welche Daten wir
              erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
              welchem Zweck das geschieht.
            </p>

            <p>
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
              bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </p>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <h2 className="h3 section-title">2. SSL-Verschlüsselung</h2>
            <p>
              Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen,
              die Sie an uns als Seitenbetreiber senden, eine
              SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
              daran, dass die Adresszeile des Browsers von „http://“ auf
              „https://“ wechselt und an dem Schloss-Symbol in Ihrer
              Browserzeile.
            </p>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <h2 className="h3 section-title">3. Server-Log-Dateien</h2>
            <p>
              Wie bei jeder Verbindung mit einem Webserver protokolliert und
              speichert der Server unseres Webhosting-Anbieters Netlify Inc,
              bestimmte technische Daten. Zu diesen Daten gehören die IP-Adresse
              und das Betriebssystem Ihres Geräts, die Daten, die Zugriffszeit,
              die Art des Browsers sowie die Browser-Anfrage inklusive der
              Herkunft der Anfrage (Referrer). Dies ist aus technischen Gründen
              erforderlich, um Ihnen unsere Website zur Verfügung zu stellen.
              Der Webhosting-Anbieter schützt diese Daten mit technischen und
              organisatorischen Massnahmen vor unerlaubten Zugriffen und gibt
              sie nicht an Dritte weiter. Soweit wir dabei personenbezogene
              Daten verarbeiten, tun wir dies aufgrund unseres Interesses, Ihnen
              die bestmögliche Nutzererfahrung zu bieten und die Sicherheit und
              Stabilität unserer Systeme zu gewährleisten.
            </p>
            <p>
              Netlify gibt Ihre persönlichen Daten nur an Dritte weiter, sofern
              dies für die Bereitstellung eines bestimmten Dienstes erforderlich
              und im Einklang mit unseren Datenschutzbestimmungen ist. Ihre
              persönlichen Daten werden von Netlify verwendet, um Dienste für
              Sie bereitzustellen und die Qualität der Webseite und der Dienste
              kontinuierlich zu verbessern, die Website und Dienste effektiver
              nutzen zu können, um Serviceniveaus zu bewerten sowie
              Verkehrsmuster zu überwachen und die Beliebtheit verschiedener
              Serviceoptionen zu messen.
            </p>
            <p>
              Netlify verwendet Cookies, um die Beliebtheit von Serviceangeboten
              zu messen. Netlify wird diese Informationen nutzen, um
              sicherzustellen, dass Besucher stets dieselbe Website sehen, wenn
              sie zur entsprechenden Web-URL zurückkehren. Ferner wird die
              Anzahl der klickenden Besucher auf unserer Website erhoben.
            </p>
            <p>
              Weiterführende Informationen finden Sie{" "}
              <a
                href="https://www.netlify.com/privacy/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                in der Datenschutzerklärung von „Netlify“
              </a>
              .
            </p>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <h2 className="h3 section-title">4. Google Fonts</h2>
            <p>
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              so genannte Web Fonts, die von Google bereitgestellt werden. Beim
              Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
              ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
            </p>
            <p>
              Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung
              zu den Servern von Google aufnehmen. Hierdurch erlangt Google
              Kenntnis darüber, dass über Ihre IP-Adresse unsere Website
              aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im
              Interesse einer einheitlichen und ansprechenden Darstellung
              unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im
              Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
            </p>
            <p>
              Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
              Standardschrift von Ihrem Computer genutzt.
            </p>
            <p>
              Weitere Informationen zu Google Web Fonts finden Sie{" "}
              <a
                href="https://developers.google.com/fonts/faq"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                unter diesem Link
              </a>{" "}
              und in der{" "}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                Datenschutzerklärung von Google
              </a>
              .
            </p>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <h2 className="h3 section-title">5. Ihre Rechte</h2>
            <p>
              Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
              Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und
              Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer
              Daten gegen das Datenschutzrecht verstösst oder Ihre
              datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt
              worden sind, können Sie sich bei der Aufsichtsbehörde beschweren.
            </p>
            <p>Sie erreichen uns unter folgenden Kontaktdaten:</p>
            <p>
              Telefon: <a
                href="tel:+41 79 329 25 87"
                className="text-secondary"
              >079 329 25 87</a>
              <br />
              <a
                href="mailto:praxis@prenosil.ch"
                className="text-secondary"
              >
                praxis@prenosil.ch
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ImpressumPage;
